import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainRed,
  navyBlue,
  aku,
  innerWidth,
  gotham,
  mainWhite,
  gothamBold,
  transHover,
  screen,
} from "../components/common/variables";
import { Link as Spy } from "react-scroll";
import Arrow from "../images/svg/right-arrow.svg";

const Wrapper = styled.div`
  padding-bottom: 90px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 2.7rem;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .primary-content {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
        margin-top: 90px;
      }

      &:first-child {
        margin-top: 15px;
        @media ${screen.small} {
          margin-top: 30px;
        }
      }

      .texts-column {
        color: ${navyBlue};
        max-width: 1000px;
        width: 100%;

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-family: ${gothamBold};
          color: ${navyBlue};
          &:hover {
            text-decoration: underline;
          }
        }

        strong {
          font-family: ${gothamBold};
        }

        h1 {
          font-size: 2rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.4rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 8px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }

        ul,
        ol {
          margin: 15px 0 30px 0;
          padding-left: 35px;
          @media ${screen.small} {
            padding-left: 45px;
          }

          li {
            color: ${navyBlue};
            line-height: 1.5;
            list-style: inherit;
            font-family: ${gotham};
            margin-bottom: 16px;
          }
        }
      }

      .img-column {
        width: 100%;
        order: -1;
        @media ${screen.small} {
          order: 1;
          padding-left: 35px;
          max-width: 330px;
        }
        @media ${screen.medium} {
          padding-left: 45px;
          max-width: 450px;
        }
        @media ${screen.large} {
          max-width: 620px;
          padding-left: 70px;
        }

        &__img {
          @media ${screen.small} {
            margin-bottom: 100px;
          }
          &:first-child {
            margin-bottom: 35px;
            @media ${screen.small} {
              margin-bottom: 100px;
            }
          }
          &:last-child {
            margin-bottom: 15px;
            @media ${screen.small} {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .featured-project {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      @media ${screen.small} {
        font-size: 2rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 30px;
      }

      .video-item {
        width: 100%;
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        outline: none;
        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.3rem;
            position: relative;
            margin-top: 20px;
            margin-left: 20px;
            padding-right: 20px;
            max-width: 440px;

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: "";
        overflow: hidden;
        position: relative;
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          margin: 0;
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }
    &__expand-btn {
      background: ${navyBlue};
      cursor: pointer;
      display: table;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      padding: 12px 30px;
      transition: ${transHover};
      margin-top: 20px;

      &:hover {
        background: ${mainRed};
      }
    }

    &__projects-link {
      background: ${navyBlue};
      transition: ${transHover};
      display: table;
      padding: 16px 29px;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      &:hover {
        background: ${mainRed};
      }
    }
  }
`;

const InforgraphicsPage = ({ data }) => {
  // Prismic content
  const pageTitle = data.content?.data?.page
    ? data.content?.data.page.text
    : `Infographics`;
  const titleTag = data.content?.data.title_tag
    ? data.content?.data.title_tag
    : `Infographics`;
  const metaDescription = data.content?.data.meta_description
    ? data.content?.data.meta_description
    : `Infographics`;
  const galleryImages = data.content?.data.image_list;
  if (!galleryImages) return null

  // image list starter length
  const [currentLength, setCurrentLength] = useState(9);

  // load more displayed image list
  const expandGalleryList = () => {
    setCurrentLength(currentLength + 9);
  };

  // image list to render to the DOM
  const renderGalleries = galleryImages.slice(0, currentLength);

  // current gallery length and total gallery length for hiding/showing the load more button
  const currentGalleryLength = renderGalleries.length;
  const totalGalleryLength = galleryImages.length;

  const primaryContent = data.content?.data.body.map((content) => (
    <div className="primary-content__row" key={content.id}>
      <div
        className="texts-column"
        dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
      />
      <div className="img-column">
        {content.items.map((item, i) => (
          <div className="img-column__img" key={i}>
            <GatsbyImage
              image={item.image.gatsbyImageData}
              alt={item.image.alt || "Banner"}
            />
          </div>
        ))}
      </div>
    </div>
  ));

  // navigate to a page with JavaScript to view the full image of infographic
  const navigateTo = (url) => {
    window.location.href = url;
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <h1 className="heading">
          <Arrow />
          {pageTitle}
        </h1>
        <div className="primary-content">{primaryContent}</div>
        <section id="gallery-thumbnail" className="featured-project">
          <h2 className="featured-project__heading">
            <Spy
              to="gallery-thumbnail"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            Our Latest Examples
          </h2>
          <ul className="featured-project__video-list">
            {renderGalleries.map((item, i) => (
              <li
                className="video-item"
                key={i}
                onClick={() =>
                  navigateTo(
                    `/preview?img=${item.original.url}&title=${item.title}&credits=${item.credits}&date=${item.edit_published_date}`
                  )
                }
              >
                <div className="video-item__texts">
                  <h2 className="video-title">{item.title}</h2>
                </div>
                <img
                  className="video-item__video"
                  src={item.image.url}
                  alt={item.image.alt}
                />
              </li>
            ))}
          </ul>
          {currentGalleryLength !== totalGalleryLength && (
            <p
              className="featured-project__expand-btn"
              onClick={expandGalleryList}
            >
              Load More
            </p>
          )}
        </section>
      </Wrapper>
    </Layout>
  );
};

export default InforgraphicsPage;

export const data = graphql`
  {
    content: prismicInfographicsPage {
      data {
        title_tag
        meta_description
        page {
          text
        }
        body {
          ... on PrismicInfographicsPageDataBodyContent {
            id
            primary {
              description {
                html
              }
            }
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        image_list {
          image {
            alt
            url
          }
          original {
            url
            alt
          }
          credits
          title
          edit_published_date
        }
      }
    }
  }
`;
